<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-text>
          <v-row align="start" class="ml-2">
            <v-card-title>Protokoll</v-card-title>
          </v-row>
          <v-row align="start" class="ml-2">
            <v-spacer />
            <v-col class="d-flex" cols="11" sm="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="comments"
            item-key="name"
            class="elevation-1 table-one"
            multi-sort
          >
            <template v-slot:item.name="{ item }">
              <div class="d-flex align-center">
                <v-avatar class="mr-2" size="26" dark>
                  <img :src="item.img" alt="" />
                </v-avatar>
                <p class="ma-0 font-weight-medium">
                  {{ item.name }}
                </p>
              </div>
            </template>
            <template v-slot:item.date="{ item }">
              {{ item.date }}
            </template>
            <template v-slot:item.status="{ item }">
              <template v-if="item.status === 'Definitiv'">
                <v-chip color="success" label small text-color="white">
                  <v-icon small left> mdi-check </v-icon>
                  {{ item.status }}
                </v-chip>
              </template>
              <template v-else>
                <v-chip color="warning" label small text-color="white">
                  {{ item.status }}
                </v-chip>
              </template>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2"
                    fab
                    x-small
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    @click="routeToQuestionsHistory"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>anzeigen</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import IconClassBar from "../../../components/base/IconClassBar.vue";
export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Table One",
  },
  components: {
    IconClassBar,
  },
  methods: {
    routeToQuestionsHistory() {
      this.$router.push({ name: "viewQuestion" });
    },
  },
  data() {
    return {
      search: "",
      selected: [],
      headers: [
        {
          text: "Autor",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Datum", value: "date" },
        { text: "Status", value: "status" },
        { text: "Aktion", value: "action" },
      ],
      viewComment: {
        name: "",
        date: "",
        action: "",
        comment: "",
      },
      newComment: {
        img: require("@/assets/images/avatars/001-man.svg"),
        name: "Hans Muster",
        date: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
        action: "add",
        comment: "",
      },
      comments: [
        {
          img: require("@/assets/images/avatars/001-man.svg"),
          name: "Jhon Doe",
          date: "23/07/2021",
          action: "add",
          comment: "Die Frage hat einschreib fehler bei der Ausgangssituation",
          status: "Definitiv",
        },
        {
          img: require("@/assets/images/avatars/002-woman.svg"),
          name: "Elizabeth Doe",
          date: "23/07/2021",
          action: "add",
          comment: "Die Frage hat einschreib fehler bei der Ausgangssituation",
          status: "Definitiv",
        },
        {
          img: require("@/assets/images/avatars/003-man-1.svg"),
          name: "Jhon Legend",
          date: "23/07/2021",
          action: "add",
          comment: "Die Frage hat einschreib fehler bei der Ausgangssituation",
          status: "Definitiv",
        },
        {
          img: require("@/assets/images/avatars/004-bald.svg"),
          name: "Juggernaut",
          date: "23/07/2021",
          action: "add",
          comment: "Musterlösung wurde bearbeitet",
          status: "Definitiv",
        },
        {
          img: require("@/assets/images/avatars/007-woman-2.svg"),
          name: "Medusa",
          date: "23/07/2021",
          action: "add",
          comment: "Musterlösung wurde bearbeitet",
          status: "Entwurf",
        },
        {
          img: require("@/assets/images/avatars/001-man.svg"),
          name: "Jhon Doe two",
          date: "23/07/2021",
          action: "add",
          comment: "Die Frage hat einschreib fehler bei der Ausgangssituation",
          status: "Definitiv",
        },
        {
          img: require("@/assets/images/avatars/002-woman.svg"),
          name: "Elizabeth Doe two",
          date: "23/07/2021",
          action: "add",
          comment: "Musterlösung wurde bearbeitet",
          status: "In Übersetzung",
        },
        {
          img: require("@/assets/images/avatars/003-man-1.svg"),
          name: "Jhon Legend two",
          date: "23/07/2021",
          action: "add",
          comment: "Musterlösung wurde bearbeitet",
          status: "QSK",
        },
        {
          img: require("@/assets/images/avatars/004-bald.svg"),
          name: "Juggernaut two",
          date: "23/07/2021",
          action: "add",
          comment: "Die Frage hat einschreib fehler bei der Ausgangssituation",
          status: "Definitiv",
        },
        {
          img: require("@/assets/images/avatars/007-woman-2.svg"),
          name: "Medusa two",
          date: "23/02/2022",
          action: "add",
          comment:
            "Frage an den Kandidaten / an die Kandidatin wurde bearbeitet",
          status: "In Übersetzung",
        },
        {
          img: require("@/assets/images/avatars/002-woman.svg"),
          name: "Elizabeth Doe three",
          date: "23/02/2022",
          action: "add",
          comment:
            "Frage an den Kandidaten / an die Kandidatin wurde bearbeitet",
          status: "In Übersetzung",
        },
        {
          img: require("@/assets/images/avatars/003-man-1.svg"),
          name: "Jhon Legend three",
          date: "23/02/2022",
          action: "add",
          comment: "Die Frage hat einschreib fehler bei der Ausgangssituation",
          status: "Definitiv",
        },
        {
          img: require("@/assets/images/avatars/004-bald.svg"),
          name: "Juggernaut three",
          date: "23/02/2022",
          action: "add",
          comment:
            "Frage an den Kandidaten / an die Kandidatin wurde bearbeitet",
          status: "In Übersetzung",
        },
        {
          img: require("@/assets/images/avatars/007-woman-2.svg"),
          name: "Medusa three",
          date: "14/04/2022",
          action: "add",
          comment:
            "Frage an den Kandidaten / an die Kandidatin wurde bearbeitet",
        },
        {
          img: require("@/assets/images/avatars/001-man.svg"),
          name: "Jhon Doe four",
          date: "14/04/2022",
          action: "add",
          comment: "Die Frage hat einschreib fehler bei der Ausgangssituation",
          status: "Definitiv",
        },
        {
          img: require("@/assets/images/avatars/002-woman.svg"),
          name: "Elizabeth Doe four",
          date: "14/04/2022",
          action: "add",
          comment:
            "Frage an den Kandidaten / an die Kandidatin wurde bearbeitet",
        },
        {
          img: require("@/assets/images/avatars/003-man-1.svg"),
          name: "Jhon Legend four",
          date: "14/04/2022",
          action: "add",
          comment:
            "Frage an den Kandidaten / an die Kandidatin wurde bearbeitet",
        },
        {
          img: require("@/assets/images/avatars/004-bald.svg"),
          name: "Juggernaut four",
          date: "14/04/2022",
          action: "add",
          comment:
            "Frage an den Kandidaten / an die Kandidatin wurde bearbeitet",
        },
      ],
      subjects: ["Administration", "Wirtschaft und Gesellschaft"],
    };
  },
};
</script>
<style lang="scss" scoped>
.textarea-line-height {
}
::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>
